import adminService from "@/services/admin.service";

/**
 * @typedef {typeof state} FraudSystemState
 * @typedef {keyof mutations} FraudSystemMutationOptions
 * @typedef {keyof actions} FraudSystemActionOptions
 */

export const state = {
  reseller_fraudulent_messages: [],
  reseller_keywords: [],
};

export const mutations = {
  SET_FRAUDULENT_MESSAGES(state, payload) {
    state.reseller_fraudulent_messages = payload;
  },
  SET_KEYWORDS(state, payload) {
    state.reseller_keywords = payload;
  },
};

export const actions = {
  /**
   *
   * @param {{commit:(mutation:MutationOptions)=>unknown}} param0
   * @param {{page:number,search:string}} payload
   */
  async getFraudulentMessages({ commit }, payload) {
    const response = await adminService.get(
      `api/get/reseller/fraud/sms?page=${payload.page}&search=${payload.search}`
    );
    commit("SET_FRAUDULENT_MESSAGES", response.data.data);
    return response.data.data;
  },
  /**
   *
   * @param {{commit:(mutation:MutationOptions)=>unknown}} param0
   * @param {{user_id:string,fraud_ignore_status:0|1}} payload
   */
  async toggleFraudWhitelist({ commit }, payload) {
    const response = await adminService.post("api/toggle/reseller/fraud/user", payload);
    return response.data;
  },
  /**
   *
   * @param {{commit:(mutation:MutationOptions)=>unknown}} param0
   * @param {{page:number,search:string}} payload
   */
  async getKeywords({ commit }, payload) {
    const response = await adminService.get(
      `api/reseller/fraud/keyword?page=${payload.page}&search=${payload.search}`
    );
    commit("SET_KEYWORDS", response.data.data);
    return response.data.data;
  },

  async addKeyword({ commit }, payload) {
    const response = await adminService.post("api/add/reseller/fraud/keyword", payload);
    return response.data;
  },

  async deleteKeyword({ commit }, payload) {
    const response = await adminService.post(
      "api/delete/reseller/fraud/keyword",
      payload
    );
    return response.data;
  },
};

export const getters = {
  fraudulentMessages: (state) => state.reseller_fraudulent_messages,
  keywords: (state) => state.reseller_keywords,
};